/**
 * 브라우저 미지원 안내를 ie8까지 지원
 * index.php의 <head></head> 안의 script 코드 중 제일 상단에 위치시키기. <script src="/nonSupport.bundle.js"></script>
 * css는 common.css에 합침
 */
import {
  getInfoOfDeviceOS,
} from './common-es6';

const agent = navigator.userAgent.toLowerCase();
const deviceOS = getInfoOfDeviceOS();

const popupOpenEvent = (popupTarget) => {
  if (deviceOS !== 'web') {
    return false;
  }

  // ie8에서의 안내를 위한 어쩔 수 없는 조치.
  // getElementsByClassName 을 지원하지 않는다.
  const popupBgClose = document.querySelectorAll('.js-popup-bg-close--nonSupport')[0];
  const popupBtnClose = document.querySelectorAll('.js-popup-btn-close--nonSupport')[0];

  popupTarget.style.display = 'block';
  document.body.style.overflow = 'hidden';
  // 팝업창 닫기
  if (popupBgClose.addEventListener) {
    popupBgClose.addEventListener('click', () => {
      popupTarget.style.display = 'none';
      document.body.style.overflow = 'auto';
    });
  } else {
    popupBgClose.attachEvent('onclick', () => {
      popupTarget.style.display = 'none';
      document.body.style.overflow = 'auto';
    });
  }
  if (popupBtnClose.addEventListener) {
    popupBtnClose.addEventListener('click', () => {
      popupTarget.style.display = 'none';
      document.body.style.overflow = 'auto';
    });
  } else {
    popupBtnClose.attachEvent('onclick', () => {
      popupTarget.style.display = 'none';
      document.body.style.overflow = 'auto';
    });
  }
  return false;
};

const browserCheck = () => {
  if (((agent.indexOf('chrome') === -1)
    && (agent.indexOf('trident') === -1))
    || (agent.indexOf('msie') !== -1)
    || (agent.indexOf('opera') !== -1)
    || (agent.indexOf('opr') !== -1)
    || (agent.indexOf('edge') !== -1)
    || (agent.indexOf('edg') !== -1)
    || (agent.indexOf('whale') !== -1)) {
    popupOpenEvent(document.getElementById('popupNonSupport'));
  }
};

const domReady = (callback) => {
  let ready = false;

  const completed = () => {
    if (!ready && (document.addEventListener || event.type === 'load' || document.readyState === 'complete')) {
      ready = true;
      detach();
      callback();
    }
  };

  const detach = () => {
    if (document.addEventListener) {
      document.removeEventListener('DOMContentLoaded', completed);
      window.removeEventListener('load', completed);
    } else {
      document.detachEvent('onreadystatechange', completed);
      window.detachEvent('onload', completed);
    }
  };

  if (document.readyState === 'complete') {
    callback();
  } else if (document.addEventListener) {
    window.addEventListener('DOMContentLoaded', completed);
    window.addEventListener('load', completed);
  } else {
    document.attachEvent('onreadystatechange', completed);
    window.attachEvent('onload', completed);

    let top = false;

    try {
      top = window.frameElement == null && document.documentElement;
    } catch (e) {
    }

    if (top && top.doScroll) {
      (function scrollCheck() {
        if (ready) return;

        try {
          top.doScroll('left');
        } catch (e) {
          return setTimeout(scrollCheck, 50);
        }

        ready = true;
        detach();
        callback();
      }());
    }
  }
};

domReady(() => {
  const browserSupportPopup = `
  <div class="popup-non-support-wrap">
    <h2 class="hide-text">브라우저 미지원 안내 팝업</h2>
    <strong class="popup-non-support__head">잠깐!<br>원활한 이용을 위해<br>다른 브라우저를 사용해주세요.</strong>
    <p class="non-support__head-sub">현재 사용 중인 브라우저는 일부 기능을 정상적으로 이용할 수 없습니다.</p>
    <div class="popup-non-support__contents">
      <p>트로스트는 크롬 브라우저에 최적화 되어 있습니다.</p>
      <p class="popup-non-support__mini-text">사용 가능 브라우저: 익스플로러 11, 사파리, 웨일</p>
      <ul class="popup-non-support__contents-item">
        <li class="item-chrome">
          <div class="item-chrome__image">
            <img src="https://assets.trost.co.kr/images/common/ic_browser_chrome.png" alt="크롬 브라우저 아이콘">
          </div>
          <div class="item-chrome__text">
            크롬 <span class="popup-non-support__contents-item-subname">CHROME</span><a class="component_text-bold component_text-body-1" href="https://www.google.com/chrome/" target="_blank">다운로드</a>
          </div>
        </li>
      </ul>
    </div>
    <div class="popup-non-support__notice">
      <p>* 오류 관련 사항은 고객센터로 문의해주세요. <span class="popup-non-support__notice-sub-txt popup-non-support__mini-text">고객센터 운영시간: 평일 10시 ~ 17시</span></p>
      <ul class="popup-non-support__notice-guide-list">
        <li><a href="https://trost.channel.io/" target="_blank">트로스트 고객센터에 문의하기</a></li>
<!--        <li><a href="https://pf.kakao.com/_mywEM#none" target="_blank">카카오톡 문의</a></li>-->
<!--        <li><a href="/about/support/" target="_blank">이메일 문의</a></li>-->
      </ul>
    </div>
    <button type="button" class="popup-pic-guide__btn js-popup-btn-close--nonSupport">확인</button>
  </div>
  <div class="popup-bg js-popup-bg-close--nonSupport"></div>`;
  const browserSupportPopupWrapEL = document.createElement('div');
  browserSupportPopupWrapEL.id = 'popupNonSupport';
  browserSupportPopupWrapEL.className = 'popup popup-non-support';
  document.body.appendChild(browserSupportPopupWrapEL);
  browserSupportPopupWrapEL.innerHTML = browserSupportPopup;

  const popupNonSupportWrap = document.getElementsByClassName('popup-non-support-wrap')[0];

  if ((agent.indexOf('msie') === -1)) {
    popupNonSupportWrap.style.height = '600px';
  }

  browserCheck();
});
